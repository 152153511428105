import moment from "moment";
import { getOrders } from "../../../services/query/order";

const getOrderTableMeta = () => {
  return {
    columns: [
      {
        Header: "Order ID",
        accessor: "order_id",
      },
      {
        Header: "Category",
        accessor: "service_category.name",
      },
      {
        Header: "Sub-category",
        accessor: "service_sub_category.name",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Created at",
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("MMM Do YY, h:mm a"),
      },
      {
        Header: "Pickup Time",
        accessor: "pickup_time",
        Cell: ({ value }) => moment(value).format("MMM Do YY, h:mm a"),
      },
      {
        Header: "Repetitive Order",
        accessor: "repetitive_order",
      },
      {
        Header: "Dropoff Time",
        accessor: "drop_off_time",
        Cell: ({ value }) => moment(value).format("MMM Do YY, h:mm a"),
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Customer",
        accessor: "customer.username",
      },
      // {
      //   Header: "",
      //   accessor: "actions",
      //   options: [
      //     (defaultValues, hideActions) => (
      //       <ModalFormOpener
      //         key="edit"
      //         submitService={updateUser}
      //         fields={updatableUserMutationFields}
      //         heading={"Let’s Edit a Country Agent!"}
      //         openerText={() => (
      //           <span>
      //             <i className="mdi mdi-square-edit-outline text-secondary font-16"></i>
      //           </span>
      //         )}
      //         openerClassName="submenu_item btn p-0 m-1"
      //         parentId="table"
      //         defaultValues={defaultValues}
      //         refreshEvent="refresh_table"
      //         size="sm"
      //         onClose={hideActions}
      //         doNotReset={true}
      //         successMessage="User updated Successfully!"
      //         permissions={["super_admin", "admin"]}
      //       />
      //     ),
      //     (defaultValues, hideActions) => (
      //       <ModalFormOpener
      //         key="delete"
      //         submitService={deleteUser}
      //         fields={updatableUserMutationFields}
      //         heading={"Delete User!"}
      //         openerText={() => (
      //           <span>
      //             <i className="mdi mdi-trash-can-outline text-secondary font-16"></i>
      //           </span>
      //         )}
      //         openerClassName="submenu_item btn p-0 m-1"
      //         parentId="table"
      //         defaultValues={defaultValues}
      //         refreshEvent="refresh_table"
      //         size="sm"
      //         onClose={hideActions}
      //         doNotReset={true}
      //         successMessage="User Deleted !"
      //         submitButtonText="Delete User"
      //         permissions={["super_admin", "admin"]}
      //       />
      //     ),
      //   ],
      // },
    ],
    actions: [],
    queryService: getOrders,
  };
};

export default getOrderTableMeta;
