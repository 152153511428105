import inputTypes from "../../../constants/form/inputTypes";
import { getStates } from "../../../services/query/company";

const companyMutationMeta = [
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    labelText: "Company Name",
    key: "name",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    labelText: "Company Website",
    key: "website",
    rules: { required: true },
  },
  {
    className: "col-12 mb-3",
    inputType: inputTypes.CUSTOM,
    render: () => <h5>Company Address</h5>,
  },
  {
    className: "col-12 mb-0",
    inputType: inputTypes.TEXT,
    key: "address_line_1",
    labelText: "Address",
    placeHolder: "Line 1",
    rules: { required: true },
  },
  {
    className: "col-12 mb-3",
    inputType: inputTypes.TEXT,
    placeHolder: "Line 2",
    key: "address_line_2",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.SELECT,
    lookupQuery: getStates,
    key: "state",
    labelText: "Company State",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "city",
    labelText: "City",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "zip",
    labelText: "Zip",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "latitude",
    labelText: "Latitude",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "longitude",
    labelText: "Longitude",
    rules: { required: true },
  },
  {
    className: "col-12 mb-3",
    inputType: inputTypes.CUSTOM,
    render: () => <h5>Owner Details</h5>,
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "first_name",
    labelText: "First Name",
    placeHolder: "Type first name",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "last_name",
    labelText: "Last Name",
    placeHolder: "Type last name",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "email",
    labelText: "Email",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "username",
    labelText: "Username",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3 pe-3",
    inputType: inputTypes.PASSWORD,
    key: "password",
    labelText: "Password",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "phone",
    labelText: "Phone Number",
    rules: { required: true },
  },
];

export default companyMutationMeta;
