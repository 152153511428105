import React, { Fragment } from "react";


function RadioInput({ meta, formInstance, isInvalid }) {
  const { key, rules = {}, options, childrenClassName } = meta;
  const { register } = formInstance;

  return (
    <div className={childrenClassName}>
      {options.map((option) => (
        <Fragment key={option.value}>
          <div className="form-check">
            <input
              id={option.value}
              className="form-check-input"
              type="radio"
              name={key.replace(/[0-9]/g, "")}
              isInvalid={isInvalid}
              value={option.value}
              // {...register(key, { ...rules })}
              {...register(key, { ...rules })}
            />
            <label
              className="form-check-label me-2"
              htmlFor={option.value}
            >
              {option.label}
            </label>
          </div>
          {/* 
          <Form.Check
            key={option.value}
            type="radio"
            label={option.label}
            value={option.value}
            name={key.replace(/[0-9]/g, "")}
            className="col-4"
            isInvalid={isInvalid}
            {...register(key, { ...rules })}
          /> */}

        </Fragment>

      ))}
    </div>
  );
}

export default RadioInput;
