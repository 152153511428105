import React from 'react';
import TableContainer from '../table/TableContainer';


const ListView = ({ filters, title, meta }) => {
  const { columns, actions, queryService, } = meta;


  return (
    <>
      <TableContainer
        title={title}
        columns={columns}
        queryService={queryService}
        isLoading={false}
        actions={actions}
        filtersMeta={filters}
        refreshEvent='refresh_table'
      />
    </>
  );
};

export default ListView;