import React from "react";

function TextAreaInput({ meta, formInstance, isInvalid }) {
  const {
    key,
    inputType,
    placeHolder,
    rules = {},
    rows = 4,
    disabled = false,
    readOnly = false
  } = meta;
  const { register } = formInstance;

  return (
    <>
      <textarea
        readOnly={readOnly}
        className="form-control"
        rows={rows || 5}
        type={inputType}
        disabled={disabled}
        placeholder={placeHolder}
        isInvalid={isInvalid}
        {...register(key, { ...rules })}
      />
    </>

  );
}

export default TextAreaInput;
