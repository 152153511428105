const endpoints = {
  LOGIN: "accounts/public/login/",
  ORGANIZATIONS: "companies/admin/companies/",
  COMPANY_MEMBERS: "companies/admin/company-members/",
  COMPANY_SERVICE_LOCATIONS: "companies/admin/service-locations/",
  USERS: "accounts/admin/users/",
  ORDERS: "orders/admin/orders/",
  USER_PROFILE: "accounts/user/profile/",
  CATEGORY: "services/admin/categories/",
  SUB_CATEGORY: "services/admin/sub-categories/",
  FILE_UPLOAD: "document-upload/",
};
export default endpoints;
