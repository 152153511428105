const genList = (total) => {
  const arr = Array.from({ length: total }).map((el, index) => {
    return {
      _id: `6321e1c15a0199dc4a5f8ade${index}`,
      company: {
        _id: `6321e1af46108a6c6872abb1${index + 5}`,
        name: `Tracode ${index}`,
        email: `Tradecode${index}@email.co.uk`,
        unique_identifier: `l81peph3479${index + 1}`,
        phone_number: Math.floor(Math.random() * 1000),
        country: "uk",
        address: "uk",
        payment_policy: "credit",
        currency: "GBP",
        agent_type: "country_agent",
        balance: 0,
        status: `${index % 6 === 0 ? true : false}`,
        signup_confirmation: true,
        createdAt: new Date(),
        updatedAt: new Date(),
        id: `6321e1af46108a6c6872abb1${index}`
      },
      country: "uk",
      first_name: `John ${index + 1}`,
      last_name: `Olson ${index + 1}`,
      email: `johnolson${index + 1}@email.co.uk`,
      phone_number: '+880 000' + Math.floor(Math.random() * 1000000),
      index: 0,
      status: true,
      role: "Admin",
      group_id: index * 10,
      password: "$2b$10$FE0JLGHc5pNxh9Ai4lpV6eFgPm.CVA3ix6yqcwG/lwHatUBX7scRa",
      createdAt: new Date(),
      updatedAt: new Date(),
      id: `6321e1c15a0199dc4a5f8ade${index + 1}`
    }
  }).reverse();

  return arr;

}

const tableListDataSource = genList(100);

export const getUserList = (params) => {

  if (params.search) {
    const dataSource = tableListDataSource.filter((data) => {
      return (
        data?.first_name?.includes(params.search || '') ||
        data?.last_name?.includes(params.search || '') ||
        data?.email?.includes(params.search || '')
      );
    });

    return { data: dataSource, meta: { total: dataSource.length }, success: true }
  }

  if (params.email) {
    const dataSource = tableListDataSource.filter((data) => data?.email?.includes(params.email || '')
    );

    return { data: dataSource, meta: { total: dataSource.length }, success: true }
  }

  if (parseInt(params.page_size) === 0) {
    return { data: tableListDataSource, meta: { total: tableListDataSource.length }, success: true };
  }

  else {
    const pageSize = parseInt(params?.page_size) || 10;
    const current = parseInt(params?.page) || 1;

    const dataSource = [...tableListDataSource].slice(
      ((current - 1) * pageSize),
      ((current) * pageSize),
    );

    return { data: dataSource, meta: { total: tableListDataSource.length }, success: true }
  }
}

export const createNewUser = (params) => {

  const newUserInfo = { ...params }
  console.log(newUserInfo)

  // const newUser = {
  //   _id: `6321e1c15a0199dc4a5f8ade${tableListDataSource.length}`,
  //   company: {
  //     _id: `6321e1af46108a6c6872abb1${tableListDataSource.length + 5}`,
  //     name: `Tracode ${tableListDataSource.length}`,
  //     email: `Tradecode${tableListDataSource.length}@email.co.uk`,
  //     unique_identifier: `l81peph3479${tableListDataSource.length + 1}`,
  //     phone_number: Math.floor(Math.random() * 1000),
  //     country: "uk",
  //     address: "uk",
  //     payment_policy: "credit",
  //     currency: "GBP",
  //     agent_type: "country_agent",
  //     balance: 0,
  //     status: true,
  //     signup_confirmation: true,
  //     createdAt: new Date(),
  //     updatedAt: new Date(),
  //     id: `6321e1af46108a6c6872abb1${tableListDataSource.length}`
  //   },
  //   ...params,
  //   country: "uk",
  //   index: 0,
  //   group_id: tableListDataSource.length * 10,
  //   createdAt: new Date(),
  //   updatedAt: new Date(),
  //   id: `6321e1c15a0199dc4a5f8ade${tableListDataSource.length}`
  // }
  // tableListDataSource.push(newUser);

  return { success: true }
}