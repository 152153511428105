import endpoints from "../../constants/endpoints";
import { adminApi } from "../client";

export const getProfile = async (params) => {
  return await adminApi({
    endpoint: endpoints.USER_PROFILE,
    method: "get",
  });
};

export const getUsers = async (params) => {
  return await adminApi({
    endpoint: endpoints.USERS,
    method: "get",
    params: { ...params },
  });
};

export const createUser = async (data) => {
  const payload = { ...data, u_type: "platform_user", role: "admin" };

  return await adminApi({
    endpoint: endpoints.USERS,
    method: "post",
    data: payload,
  });
};

export const updateUser = async (data) => {
  const payload = {
    ...data,
  };
  return await adminApi({
    endpoint: `${endpoints.USERS}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const resetPassword = async (data) => {
  // return await adminApi({
  //   endpoint: endpoints.RESET_PASSWORD,
  //   method: "post",
  //   data: data,
  // });
};

export const verifyResetPassword = async (data) => {
  // return await adminApi({
  //   endpoint: endpoints.RESET_PASSWORD_VERIFY,
  //   method: "post",
  //   data: data,
  // });
};

export const changePassword = async (data) => {
  // return await adminApi({
  //   endpoint: endpoints.CHANGE_PASSWORD,
  //   method: "post",
  //   data: data,
  // });
};
