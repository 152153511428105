import React, { useState } from 'react'
import { Link, useLocation } from "react-router-dom"
import "./Menus.css";

const Menus = ({ data }) => {

  const location = useLocation();
  const [activeKey, setActiveKey] = useState({});

  const handleClick = (item, activeKey) => {
    activeKey[item.key] ?
      setActiveKey({ ...activeKey, [item.key]: false }) :
      setActiveKey({ ...activeKey, [item.key]: true });
  }

  const renderMenuItem = (item, location, activeKey, setActiveKey) => {
    let menuItem;
    if (!item?.subMenus) {
      menuItem = (
        <li className="nav-item" key={item.key}>
          <Link to={item?.path} className={`nav-link ${location?.pathname === item?.path ? 'nav-link-active' : ''}`}>
            {item?.icon}
            <span>{item?.title}</span>
          </Link>
        </li>)
    } else {
      menuItem = item?.subMenus?.length !== 0 && (
        <li className="nav-item" key={item.key}>
          <span className={`nav-link has-submenu ${activeKey[item.key] ? 'expanded' : ''}`} role="button"
            onClick={() => handleClick(item, activeKey)}>
            {item?.icon}
            <span>{item.title}</span>
          </span>
          <div className={`submenu-list collapse ${activeKey[item.key] ? 'show' : ''}`} >
            <ul className="nav flex-column">
              {item?.subMenus?.map((i, index) => renderMenuItem(i, location, activeKey, setActiveKey))}
            </ul>
          </div>
        </li>
      )
    }

    return menuItem;
  }

  return (data.map((item, index) => renderMenuItem(item, location, activeKey, setActiveKey)))
}

export default Menus