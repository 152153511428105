import React from 'react'
import { Link } from 'react-router-dom'
import './PageHeader.css'

const PageHeader = ({ title, breadCrumbItems }) => {
  return (

    <div className="row">
      <div className="col-sm-12">
        <div className="page-title-box">
          <div className="float-end">
            <ol className="breadcrumb">
              {breadCrumbItems?.map((el, index) =>
              (<li className="breadcrumb-item" key={el.path}>
                <Link to={el.path}>{el.title}</Link>
              </li>))}
            </ol>
          </div>
          <h4 className="page-title">{title || 'Page Title'}</h4>
        </div>
        {/*end page-title-box*/}
      </div>
      {/*end col*/}
    </div>
  )
}

export default PageHeader
