import React, { Fragment } from "react";
import { useRoutes } from "react-router-dom";
import './App.css';
import routesMeta from "./configs/meta/routesMeta";
import { AuthProvider } from "./contexts/JWTContext";

function App() {

  const content = useRoutes(routesMeta);

  return (
    <Fragment>
      <AuthProvider>
        {content}
      </AuthProvider>
    </Fragment>
  );
}

export default App;

// Add comment