import endpoints from "../../constants/endpoints";
import { publicApi } from "../client";

export const login = async (data) => {
  // return {
  //   success: true,
  //   data: {
  //     first_name: "John",
  //     last_name: "Doe",
  //     email: "",
  //     password: "",
  //     type: "",
  //     access_token: "1234566789",
  //     refresh_token: "1234567899",
  //   },
  // };
  return await publicApi({
    endpoint: endpoints.LOGIN,
    method: "post",
    data: data,
  });
};

export const updateProfile = async (data) => {
  // const { u_type, ...newData } = data;
  // const payload = {
  //   ...newData,
  //   image: Array.isArray(data.image) ? data.image[0] : data.image,
  // };
  // return await adminApi({
  //   endpoint: `${endpoints.USER_PROFILE}`,
  //   method: "patch",
  //   data: payload,
  // });
};

export const logout = async (data) => {
  return {
    success: true,
  };
  // return await adminApi({
  //   endpoint: endpoints.LOGOUT,
  //   method: "delete",
  // });
};

export const refreshAccessToken = async (data) => {
  // return await publicApi({
  //   endpoint: `${endpoints.REFRESH_TOKEN}`,
  //   method: "post",
  //   data: data,
  // });
};

// export const forgotPassword = async (data) => {
//   const payload = {
//     email: data.email,
//   };

//   return await adminApi({
//     endpoint: endpoints.FORGOT_PASSWORD,
//     method: "post",
//     data: payload,
//   });
// };

// export const resetPassword = async (data) => {
//   const payload = {
//     password: data.password,
//   };

//   return await adminApi({
//     endpoint: endpoints.RESET_PASSWORD,
//     method: "patch",
//     data: payload,
//     headers: { Authorization: `Bearer ${data.token} ` },
//   });
// };
