import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { eventBus } from "../../services/eventBus";
import { getUrlSearchParams } from "../../utils/urls";
import Pagination from "./pagination/Pagination";
import Table from "./Table";
import TableFilter from "./TableFilter";
import TablePrimaryAction from "./TablePrimaryAction";

export const DEFAULT_PAGE_SIZE = 10;

const TableContainer = ({
  queryService,
  columns,
  title,
  actions,
  filtersMeta,
  refreshEvent,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [pageData, setPageData] = useState({ rowData: [], total: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(
    getUrlSearchParams(searchParams).page_size || DEFAULT_PAGE_SIZE
  );
  const [goToPageInput, setGoToPageInput] = useState(1);

  // const { checkPermission } = usePermission();
  // const processedActions = actions.filter((i) =>
  //   i.props.permissions ? checkPermission(i.props.permissions) : true
  // );

  const processedActions = actions; // Check for permissions here later

  const performQuery = useCallback(() => {
    const urlSearchParams = getUrlSearchParams(searchParams);
    setIsLoading(true);
    const res = queryService({
      ...urlSearchParams,
    }).then((info) => {
      const { meta, data } = info;
      setPageData({
        rowData: data,
        total: meta?.total || 0,
      });
      setIsLoading(false);
      return info;
    });
    return res;
  }, [queryService, searchParams]);

  // INITIAL PERFORM
  useEffect(() => {
    performQuery();
  }, [searchParams, performQuery]);

  useEffect(() => {
    eventBus.subscribe(refreshEvent, performQuery);
    return () => {
      eventBus.unsubscribe(refreshEvent);
    };
  }, [performQuery, refreshEvent]);

  const handlePaginationChange = (current, pageSize = DEFAULT_PAGE_SIZE) => {
    setPageLimit(pageSize);
    setCurrentPage(current);
    setGoToPageInput(current);
    setIsLoading(true);

    const newParams = {
      ...getUrlSearchParams(searchParams),
      page_size: pageSize,
      page: current,
    };
    setSearchParams(newParams);
  };

  return (
    <div className="col-12 table-responsive">
      {filtersMeta && (
        <TableFilter
          filtersMeta={filtersMeta}
          queryService={performQuery}
          params={searchParams}
          setParams={setSearchParams}
        />
      )}
      <div className="table-components-container">
        <TablePrimaryAction title={title} actions={processedActions} />
        <div className="table-items-container card border-0 rounded p-4 custom-shadow-one">
          <div className="row">
            <div className="col-12">
              <div className="table-container">
                <Table
                  columns={columns || []}
                  data={pageData.rowData || []}
                  isLoading={isLoading}
                  showRowActionsInDropdown={false}
                />
                <Pagination
                  current={currentPage}
                  setCurrent={setCurrentPage}
                  total={pageData.total}
                  onChange={handlePaginationChange}
                  pageSize={pageLimit}
                  goToPageInput={goToPageInput}
                  setGoToPageInput={setGoToPageInput}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableContainer;
