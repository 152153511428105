import React from 'react'
import PageSize from './PageSize';
import PaginatedItems from './PaginatedItems';
import './Pagination.css';

const Pagination = ({
  current,
  total,
  onChange,
  pageSize,
}) => {

  return (
    <nav aria-label="..." className="d-flex justify-content-between flex-wrap">
      <div>
        <PageSize pageSize={pageSize} onChange={onChange} total={total} />
      </div>
      <PaginatedItems
        current={current}
        pagesCount={pageSize ? Math.ceil(total / pageSize) : 1}
        pageSize={pageSize}
        onChange={onChange}
      />

    </nav>
  )
}

export default Pagination;

