import React from 'react'

const TablePrimaryAction = ({ title, actions }) => {
  return (
    <div className="table-primary-action my-4 d-flex justify-content-between align-items-center">
      <h4 className="table_title card-title">{title}</h4>
      <div className='d-flex flex-wrap'>
        {actions}
      </div>
    </div>
  )
}

export default TablePrimaryAction
