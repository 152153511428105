import React from "react";

function DateTimeInput({ meta, formInstance, isInvalid }) {
  const { key, placeHolder, rules = {} } = meta;
  const { register } = formInstance;

  return (
    <input
      type="datetime-local"
      className="form-control datepicker-input"
      placeholder={placeHolder || ""}
      isInvalid={isInvalid}
      {...register(key, { ...rules })}
    />
  );
}

export default DateTimeInput;
