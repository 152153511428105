import React from 'react'
import ListView from '../../components/listvVew/ListView'
import PageHeader from '../../components/pageHeader/PageHeader'
import { userFilterMeta } from '../../configs/meta/listFilterMeta/userFilterMeta'
import getUserTableMeta from '../../configs/meta/listMeta/userTableMeta'
import { HOME_PATH, TABLES_PATH } from '../../constants/route'

const Tables = () => {


  return (
    <>
      <PageHeader title={'Tables'} breadCrumbItems={[{ title: 'Dashboard', path: HOME_PATH }, { title: 'Tables', path: TABLES_PATH }]} />
      <div className='row'>
        {/* <TableBuilder/> */}
        <ListView
          title={'User List'}
          filters={userFilterMeta}
          meta={getUserTableMeta()}
        />
      </div>
    </>
  )
}

export default Tables