import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createUser,
  getUsers,
  updateUser,
} from "../../../services/query/userManagement";
import updatableUserMutationFields from "../mutationMeta/updatableUserMutationFields";
import userMutationFields from "../mutationMeta/userMutationFields";

const getBidTableMeta = () => {
  return {
    columns: [
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone_number",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <div
            className={`badge ${
              !value ? "badge-soft-danger" : "badge-soft-success"
            } `}
          >
            {value ? "Active" : "Offline"}
          </div>
        ),
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues, hideActions) => (
            <ModalFormOpener
              key="edit"
              submitService={updateUser}
              fields={updatableUserMutationFields}
              heading={"Let’s Edit a Country Agent!"}
              openerText={() => (
                <span>
                  <i className="mdi mdi-square-edit-outline text-secondary font-16"></i>
                </span>
              )}
              openerClassName="submenu_item btn p-0 m-1"
              parentId="table"
              defaultValues={defaultValues}
              refreshEvent="refresh_table"
              size="sm"
              onClose={hideActions}
              doNotReset={true}
              successMessage="User updated Successfully!"
              permissions={["super_admin", "admin"]}
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key={1}
        submitService={createUser}
        fields={userMutationFields}
        heading={"Create a New User!"}
        openerText={() => <span>+ New User</span>}
        openerClassName="primaryBtn_sm btn btn-primary btn-sm"
        refreshEvent="refresh_table"
        size="sm"
        successMessage="User created Successfully!"
        doNotReset={true}
      />,
    ],
    queryService: getUsers,
  };
};

export default getBidTableMeta;
