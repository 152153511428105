const companyMembersTableColumns = [
  {
    Header: "First Name",
    accessor: "user.first_name",
  },
  {
    Header: "Last Name",
    accessor: "user.last_name",
  },
  {
    Header: "Username",
    accessor: "user.username",
  },
  {
    Header: "Email",
    accessor: "user.email",
  },
  {
    Header: "Owner",
    accessor: "is_owner",
    align: "center",
    Cell: ({ value }) => (
      <div style={{ textAlign: "center" }}>
        {value ? (
          <i className="fa fa-check" style={{ color: "green" }}></i>
        ) : (
          <i className="mdi mdi-close" style={{ color: "red" }}></i>
        )}
      </div>
    ),
  },
];

export default companyMembersTableColumns;
