import inputTypes from "../../../constants/form/inputTypes";
import {
  getCategoriesForDropdown,
  getSubCategoriesForDropdown,
} from "../../../services/query/company";

export const orderFilterMeta = [
  {
    inputType: inputTypes.TEXT,
    className: "col-12 mb-3",
    key: "search",
    labelText: "Search",
    placeHolder: "Search by first name, last name or id",
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.SELECT,
    lookupQuery: getCategoriesForDropdown,
    key: "service_category",
    labelText: "Service Category",
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.SELECT,
    lookupQuery: getSubCategoriesForDropdown,
    key: "service_sub_category",
    labelText: "Service Sub Categories",
    dependencies: ["service_category"],
    onDependencyValueChange: (
      { service_category },
      key,
      { performLookupQuery }
    ) => {
      performLookupQuery?.({ category: service_category });
    },
  },
];
