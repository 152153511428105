import React from "react";

function DateInput({ meta, formInstance, isInvalid }) {
  const { key, placeHolder, rules = {}, readOnly = false } = meta;
  const { register } = formInstance;

  return (
    <input
      type="date"
      readOnly={readOnly}
      className="form-control datepicker-input"
      placeholder={placeHolder || ""}
      isInvalid={isInvalid}
      {...register(key, { ...rules })}
    />
  );
}

export default DateInput;
