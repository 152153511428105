import React from "react";
import { Link } from "react-router-dom";
import FormBuilder from "../../components/form/formBuilder/FormBuilder";
import PageHeader from "../../components/pageHeader/PageHeader";
import fields from "../../configs/meta/formFilelds/fields";
import { FORMS_PATH, HOME_PATH } from "../../constants/route";

const Forms = () => {
  const handleSubmit = (data) => {
    console.log(data);
  };

  return (
    <>
      <PageHeader
        title={"Forms"}
        breadCrumbItems={[
          { title: "Dashboard", path: HOME_PATH },
          { title: "Forms", path: FORMS_PATH },
        ]}
      />
      <div className="card rounded-2 p-4">
        <FormBuilder
          onSubmit={handleSubmit}
          fields={fields}
          submitButton={{
            className: "btn btn-primary btn-lg ",
            label: "Login",
            isDisabled: false,
          }}
          className=""
          doNotReset={true}
          actionsPrev={
            [
              // <div>
              //   <Link to={"#"} className="display-block">
              //     Forgot Password
              //   </Link>
              //   <br />
              // </div>,
            ]
          }
        />
      </div>
      {/*end row*/}
    </>
  );
};

export default Forms;
