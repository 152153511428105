import { getUserList } from '../../mockData/list';

export const getUsers = async (params) => {
  const data = await getUserList(params);
  if (data.success) {
    return data;
  } else
    return [];
};


export const updateUser = async (params) => {
  console.log(params)
  return { success: true }
};

export const createUser = async (params) => {
  console.log(params);
  return { success: true }
};

export const deleteUser = async (params) => {
  console.log(params);
  return { success: true }
};
