import axios from "axios";

const axiosInstance = axios.create();
export const axiosPublicInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  }
  return config;
});

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.status === 401) {
//       // refresh api call with refresh token
//     }
//     throw (
//       error.response?.data || {
//         success: false,
//         message: "Something went wrong",
//       }
//     );
//   }
// );

export default axiosInstance;
