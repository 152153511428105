const companyServiceLocationsTableColumn = [
  {
    Header: "States",
    accessor: "states",
    Cell: ({ value }) => value.map((val) => val.state.name).join(", "),
  },
  {
    Header: "Service Category",
    accessor: "service_category.name",
  },
  {
    Header: "Service Sub-category",
    accessor: "service_sub_category.name",
  },
];

export default companyServiceLocationsTableColumn;
