import React, { useCallback, useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";
const RichTextInput = ({
  meta,
  defaultValue,
  formInstance,
  dependencyValues,
}) => {
  const { key, rules = {} } = meta;
  const { control } = formInstance;
  const [display, setDisplay] = useState(true);

  const toggleVisibilityOfRichText = useCallback((condition) => {
    if (condition) setDisplay(false);
    else setDisplay(true);
  }, []);

  useEffect(() => {
    if (!meta.dependencies?.length || !Object.keys(dependencyValues).length)
      return;
    meta?.onDependencyValueChange(dependencyValues, meta.key, {
      toggleVisibilityOfRichText,
    });
  }, [meta, dependencyValues, toggleVisibilityOfRichText]);
  return (
    display && (
      <Controller
      className="form-control border border-danger"
        control={control}
        defaultValue={defaultValue}
        name={key}
        shouldUnregister
        rules={rules}
        render={({ field: { onChange, value, ref } }) => (
          <Editor
            apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
            onInit={(evt, editor) => (ref.current = editor)}
            initialValue={defaultValue}
            onEditorChange={onChange}
            value={value}
            init={{
              height: 240,
              menubar: false,
              branding: false,
              paste_preprocess: function (plugin, args) {
                args.content = args.content
                  .replace(/<pre[^>]*>/g, "<p>")
                  .replace(/<\/pre>/g, "</p>");
              },
              // plugins: [
              //   "advlist lists autolink link image charmap print preview anchor",
              //   "searchreplace visualblocks code fullscreen",
              //   "insertdatetime media table paste code help wordcount",
              // ],
              plugins:
                "advlist lists autolink link image charmap print preview anchor",

              toolbar:
                "undo redo | formatselect | fontselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        )}
      />
    )
  );
};

export default RichTextInput;
