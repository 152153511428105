import React from 'react'

const PageSize = ({ pageSize, onChange, total }) => {
  return (
    <div className="dataTable-dropdown ">
      <span className='mx-2'>Show</span>
      <label>
        <select className="dataTable-selector" name='pageSizeValue'
          onChange={(e) => {
            onChange(1, parseInt(e.target.value));
          }}
          value={pageSize}
        >
          <option value={10} selected="">
            10
          </option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={0}>All</option>
        </select>{" "}
      </label>
      <span className='mx-2'>of {total} items</span>
    </div>
  )
}

export default PageSize