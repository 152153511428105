import inputTypes from "../../../constants/form/inputTypes";

const categoryMutationFields = [
  {
    className: "col-12 mb-3",
    inputType: inputTypes.TEXT,
    key: "name",
    labelText: "Name",
    placeHolder: "Type your first name",
    rules: { required: true },
  },
];

export default categoryMutationFields;
