import inputTypes from "../../../constants/form/inputTypes";

export const searchOnlyMeta = [
  {
    inputType: inputTypes.TEXT,
    className: "col-8 mb-3",
    key: "search",
    labelText: "Search",
    placeHolder: "Search",
  },
];
