function FilePreview({ file, deleteFile }) {
  const isImage = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(file);
  const splittedFileUrl = file?.split("/");
  return (
      <div className="d-flex" style={{
            width: '200px',
            height: '200px',
            objectFit: 'cover'
          }} key={file}>
        <div className="position-relative">
          <div className="position-absolute end-0 me-2 mt-1 "><i onClick={() => {deleteFile(file)}} className="fa fa-window-close font-16 pointer" style={{cursor: 'pointer'}} aria-hidden="true"></i></div>
          {isImage ? (
            <img 
              src={file} 
              alt="..." 
              style={{
                width: '200px',
                height: '200px',
                objectFit: 'cover'
              }} 
            />
          ) : (
            splittedFileUrl && (
              <div className="badge-light p-2 width-200px">
                {splittedFileUrl[splittedFileUrl.length - 1]
                  .split("-")
                  .slice(1)
                  .join("-")}
              </div>
            )
          )}
        </div>
    </div>
  );
}

export default FilePreview;
