import React from 'react'
import { eventBus } from '../../services/eventBus';
import { getPaginationParams } from '../../utils/urls';
import FormBuilder from '../form/formBuilder/FormBuilder';

const TableFilter = ({ filtersMeta, queryService, params, setParams }) => {

  const handleSubmit = (data) => {
    Object.keys(data).forEach((key) => (!data[key] && delete data[key]));
    const newParams = { ...getPaginationParams(params), ...data };
    setParams(newParams);
    queryService();
  };

  const reset = () => {
    setParams({});
  };


  const submitButton = {
    className: 'table-search-btn btn btn-primary mx-2',
    label: <span><i className='ti ti-search font-13'></i> Search</span>,
  };

  return (
    <div className='card border-0 rounded table-filter-container custom-shadow-one'>
      <div className='filter_form p-4'>
        <FormBuilder
          className="table_filter row"
          onSubmit={handleSubmit}
          fields={filtersMeta}
          submitButton={submitButton}
          doNotReset={true}
          resetEvent="reset_filter_form"
          inputsContainerClassName='row col col-lg-9'
          actionsContainerClassName='row col col-lg-3 d-flex align-items-start mx-2 my-3 pt-1'
          actions={[
            <button
              key="Reset"
              className='table-reset-btn btn btn-primary'
              type='button'
              onClick={() => {
                eventBus.publish('reset_filter_form');
                reset()
                // router.replace(router.pathname, undefined, { shallow: true });
              }}
            >
              <i className='mdi mdi-restore font-13'></i>  Reset
            </button>
          ]}
        />
      </div>

    </div>
  )
}

export default TableFilter
