import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import Modal from "../modal/Modal";
import Table from "../table/Table";

function ModalTableOpener({
  openerText,
  heading = "",
  openerClassName = "primaryBtn",
  parentId,
  size,
  getData,
  columns,
  onClose,
  id,
}) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const mountPoint = parentId && document.getElementById(parentId);
  const onClick = () => {
    setShow(true);
  };

  const performFormDataLookup = useCallback(async () => {
    const data = await getData(id);
    setData(data.data);
  }, [getData, id]);

  useEffect(() => {
    if (getData && show) performFormDataLookup();
  }, [performFormDataLookup, getData, show]);

  const handleModalClose = useCallback(() => {
    setShow(false);
    onClose?.();
  }, [onClose]);

  const modal = (
    <Modal handleModalClose={handleModalClose} size={size}>
      <div className="new_agent_modal_header">
        <h3 className="table_title card-title">{heading}</h3>
      </div>
      <hr />
      <div className="new_agent_form_container mb-2">
        <Table columns={columns} data={data} />
      </div>
    </Modal>
  );

  return (
    <div>
      {show ? (mountPoint ? createPortal(modal, mountPoint) : modal) : null}
      <button onClick={onClick} className={`${openerClassName}`}>
        {openerText()}
      </button>
    </div>
  );
}

export default ModalTableOpener;
