import React from "react";
import ListView from "../../components/listvVew/ListView";
import PageHeader from "../../components/pageHeader/PageHeader";
import getOrderTableMeta from "../../configs/meta/listMeta/orderTableMeta";
import { HOME_PATH, ORDER_MANAGEMENT } from "../../constants/route";
import { orderFilterMeta } from "../../configs/meta/listFilterMeta/orderFilterMeta";

const OrderManagement = () => {
  return (
    <>
      <PageHeader
        title={"Order Management"}
        breadCrumbItems={[
          { title: "Dashboard", path: HOME_PATH },
          { title: "Orders", path: ORDER_MANAGEMENT },
        ]}
      />
      <div className="row">
        {/* <TableBuilder/> */}
        <ListView
          title={"Order List"}
          filters={orderFilterMeta}
          meta={getOrderTableMeta()}
        />
      </div>
    </>
  );
};

export default OrderManagement;
