import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/images/logo-sm.png";
import backgroundImg from "../../assets/images/p-1.png";
import FormBuilder from "../../components/form/formBuilder/FormBuilder";
import loginFields from "../../configs/meta/formFilelds/loginFields";
import { HOME_PATH } from "../../constants/route";
import useAuth from "../../hooks/useAuth";

const Login = () => {
  const { signIn, isAuthenticated, isInitialized } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && isInitialized) navigate(HOME_PATH);
  }, [isAuthenticated, isInitialized, navigate]);

  const handleSubmit = async (data) => {
    const response = await signIn(data.user_name, data.user_password);
    if (response.success) {
      navigate(HOME_PATH);
    }
  };
  return (
    <div
      className="auth-page"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <div className="container-md">
        <div className="row vh-100 d-flex justify-content-center">
          <div className="col-12 align-self-center">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4 mx-auto">
                  <div className="card">
                    <div className="card-body p-0 auth-header-box">
                      <div className="text-center p-3">
                        <a href="index.html" className="logo logo-admin">
                          <img
                            src={logo}
                            height={50}
                            alt="logo"
                            className="auth-logo"
                          />
                        </a>
                        <h4 className="mt-3 mb-1 fw-semibold text-white font-18">
                          Let's Get Started Metrica
                        </h4>
                        <p className="text-muted  mb-0">
                          Sign in to continue to Metrica.
                        </p>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <FormBuilder
                        onSubmit={handleSubmit}
                        fields={loginFields}
                        submitButton={{
                          className: "btn btn-primary mt-2",
                          label: (
                            <>
                              Login <i className="fa fa-sign-in-alt"></i>
                            </>
                          ),
                          isDisabled: false,
                        }}
                        className="mt-4"
                        doNotReset={true}
                      />
                      <div className="m-3 text-center text-muted">
                        <p className="mb-0">
                          Forgot your Password ?{" "}
                          <a
                            href="auth-register.html"
                            className="text-primary ms-2"
                          >
                            Reset
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
