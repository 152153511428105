import React from "react";

const InputError = ({ error }) => {
  if (!error) return null;
  console.log(error);

  if (error?.type === "required") {
    return (
      <small id="emailHelp" className="form-text text-danger">
       {error.message || "This is required"}
    </small>

    );
  } else
    return (
      <small id="emailHelp" className="form-text text-danger">
       {error.message || "This is required"}
    </small>
    );
};

export default InputError;
