import {
  BID_MANAGEMENT,
  CATEGORY_MANAGEMENT,
  COMPANY_MANAGEMENT,
  // FORMS_PATH,
  HOME_PATH,
  // MODAL_PATH,
  ORDER_MANAGEMENT,
  SUB_CATEGORY_MANAGEMENT,
  // TABLES_PATH,
  USER_MANAGEMENT,
} from "../../constants/route";

const sideBarMeta = [
  {
    group: "Main",
    key: "main",
    menuLabel: (
      <li className="menu-label mt-0">
        M<span>ain</span>
      </li>
    ),
    menus: [
      {
        path: HOME_PATH,
        title: "Dashboards",
        key: "dashboards",
        icon: <i className="ti ti-home menu-icon"></i>,
      },
      {
        path: CATEGORY_MANAGEMENT,
        title: "Category Management",
        key: "category",
        icon: <i className="fa fa-list-alt menu-icon"></i>,
      },
      {
        path: SUB_CATEGORY_MANAGEMENT,
        title: "Sub Category Management",
        key: "subcategory",
        icon: <i className="fa fa-list-alt menu-icon"></i>,
      },
      {
        path: USER_MANAGEMENT,
        title: "User Management",
        key: "user",
        icon: <i className="ti ti-user menu-icon"></i>,
      },
      {
        path: COMPANY_MANAGEMENT,
        title: "Company Management",
        key: "company",
        icon: <i className="ti ti-building menu-icon"></i>,
      },
      {
        path: ORDER_MANAGEMENT,
        title: "Order Management",
        key: "order",
        icon: <i className="fa fa-cart-arrow-down menu-icon"></i>,
      },
      {
        path: BID_MANAGEMENT,
        title: "Bid Management",
        key: "bid",
        icon: <i className="fa fa-gavel menu-icon"></i>,
      },
      // {
      //   title: "Analytics",
      //   key: "analytics",
      //   icon: <i className="ti ti-stack menu-icon"></i>,
      //   subMenus: [
      //     {
      //       path: "#dummy1",
      //       title: "Dummy1",
      //       key: "dummy1-chart",
      //     },
      //     {
      //       path: "#dummy2",
      //       title: "Dummy2",
      //       key: "dummy2-chart",
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   group: "Components",
  //   key: "components",
  //   menuLabel: (
  //     <li className="menu-label mt-0">
  //       C<span>omponents</span>
  //     </li>
  //   ),
  //   menus: [
  //     {
  //       path: FORMS_PATH,
  //       title: "Forms",
  //       key: "forms",
  //       icon: <i className="ti ti-forms menu-icon"></i>,
  //     },
  //     {
  //       path: MODAL_PATH,
  //       title: "Modals",
  //       key: "modals",
  //       icon: <i className="ti ti-planet menu-icon"></i>,
  //     },
  //     {
  //       path: TABLES_PATH,
  //       title: "Tables",
  //       key: "tables",
  //       icon: <i className="ti ti-table menu-icon"></i>,
  //     },
  //   ],
  // },
];
export default sideBarMeta;
