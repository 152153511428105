import React from 'react'
import ReactPaginate from 'react-paginate';

const PaginatedItems = ({ pagesCount, current, pageSize, onChange }) => {
  return (
    <div className='custom-react-pagination'>
      <ReactPaginate
        className='pagination pagination-sm'
        nextLabel={'Next'}
        onPageChange={(e) => {
          onChange(e.selected + 1, pageSize);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pagesCount}
        previousLabel={'Previous'}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        forcePage={parseInt(current) - 1}
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination-container mb-0"
        activeClassName="active"
      />
    </div>
  )
}

export default PaginatedItems