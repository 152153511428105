import AuthGuard from "../../components/guards/AuthGuard";
import HomeMarkup from "../../components/homeMarkup/HomeMarkup";
import {
  BID_MANAGEMENT,
  CATEGORY_MANAGEMENT,
  COMPANY_MANAGEMENT,
  FORMS_PATH,
  HOME_PATH,
  LOGIN_PATH,
  MODAL_PATH,
  ORDER_MANAGEMENT,
  SUB_CATEGORY_MANAGEMENT,
  TABLES_PATH,
  USER_MANAGEMENT,
} from "../../constants/route";
import DashboardLayout from "../../containers/dashboardLayout/DashboardLayout";
import Forms from "../../pages/forms/Forms";
import Login from "../../pages/login/Login";
import Modals from "../../pages/modals/Modals";
import NotFound from "../../pages/notFound/NotFound";
import BidManagement from "../../pages/tables/BidManagement";
import CategoryManagement from "../../pages/tables/CategoryManagement";
import CompanyManagement from "../../pages/tables/CompanyManagement";
import OrderManagement from "../../pages/tables/OrderManagement";
import SubCategoryManagement from "../../pages/tables/SubCategoryManagement";
import Tables from "../../pages/tables/Tables";
import UserManagement from "../../pages/tables/UserManagement";

const routesMeta = [
  {
    path: HOME_PATH,
    element: (
      <AuthGuard>
        <DashboardLayout children={<HomeMarkup />} />
      </AuthGuard>
    ),
  },
  {
    path: TABLES_PATH,
    element: (
      <AuthGuard>
        <DashboardLayout children={<Tables />} />
      </AuthGuard>
    ),
  },
  {
    path: COMPANY_MANAGEMENT,
    element: (
      <AuthGuard>
        <DashboardLayout children={<CompanyManagement />} />
      </AuthGuard>
    ),
  },
  {
    path: ORDER_MANAGEMENT,
    element: (
      <AuthGuard>
        <DashboardLayout children={<OrderManagement />} />
      </AuthGuard>
    ),
  },
  {
    path: BID_MANAGEMENT,
    element: (
      <AuthGuard>
        <DashboardLayout children={<BidManagement />} />
      </AuthGuard>
    ),
  },
  {
    path: CATEGORY_MANAGEMENT,
    element: (
      <AuthGuard>
        <DashboardLayout children={<CategoryManagement />} />
      </AuthGuard>
    ),
  },
  {
    path: SUB_CATEGORY_MANAGEMENT,
    element: (
      <AuthGuard>
        <DashboardLayout children={<SubCategoryManagement />} />
      </AuthGuard>
    ),
  },
  {
    path: USER_MANAGEMENT,
    element: (
      <AuthGuard>
        <DashboardLayout children={<UserManagement />} />
      </AuthGuard>
    ),
  },
  {
    path: FORMS_PATH,
    element: (
      <AuthGuard>
        <DashboardLayout children={<Forms />} />
      </AuthGuard>
    ),
  },
  {
    path: LOGIN_PATH,
    element: <Login />,
  },
  {
    path: MODAL_PATH,
    element: (
      <AuthGuard>
        <DashboardLayout children={<Modals />} />
      </AuthGuard>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routesMeta;
