import ModalConfirmation from "../../../components/actions/ConfirmationModal";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import ModalTableOpener from "../../../components/actions/ModalTableOpener";
import {
  approveCompany,
  createCompany,
  getCompanies,
  getCompanyMembers,
  getServiceLocations,
  updateCompany,
} from "../../../services/query/company";
import companyMembersTableColumns from "../columns/companyMembersTableColumns";
import companyServiceLocationsTableColumn from "../columns/companyServiceLocationsTableColumn";
import companyMutationMeta from "../mutationMeta/companyMutationMeta";
import updatableCompanyMutationMeta from "../mutationMeta/updatableCompanyMutationMeta";

const getCompanyTableMeta = () => {
  return {
    columns: [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Owner",
        accessor: "owner",
        Cell: ({ value }) => value.username,
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues, hideActions) => (
            <ModalFormOpener
              key="edit"
              submitService={updateCompany}
              defaultValues={{ ...defaultValues, ...defaultValues.address }}
              fields={updatableCompanyMutationMeta}
              heading={"Let’s Edit a Company!"}
              openerText={() => (
                <span>
                  <i className="mdi mdi-square-edit-outline text-secondary font-16"></i>
                </span>
              )}
              openerClassName="submenu_item btn p-0 m-1"
              parentId="table"
              refreshEvent="refresh_table"
              size="sm"
              onClose={hideActions}
              doNotReset={true}
              successMessage="Company updated Successfully!"
              permissions={["super_admin", "admin"]}
            />
          ),
          (defaultValues, hideActions) => (
            <ModalTableOpener
              key="view_members"
              getData={getCompanyMembers}
              id={defaultValues.id}
              columns={companyMembersTableColumns}
              heading={"Company Members!"}
              openerText={() => (
                <span className="btn btn-outline-primary">
                  <i className="mdi mdi-eye text-secondary font-16"></i> Members
                </span>
              )}
              openerClassName="submenu_item btn p-0 m-1"
            />
          ),
          (defaultValues, hideActions) => (
            <ModalTableOpener
              key="view_locations"
              getData={getServiceLocations}
              id={defaultValues.id}
              columns={companyServiceLocationsTableColumn}
              heading={"Company Service Locations!"}
              openerText={() => (
                <span className="btn btn-outline-primary">
                  <i className="mdi mdi-eye text-secondary font-16"></i>{" "}
                  Locations
                </span>
              )}
              openerClassName="submenu_item btn p-0 m-1"
            />
          ),
          (defaultValues, hideActions) =>
            !defaultValues.is_approved && (
              <ModalConfirmation
                key="approve"
                submitService={approveCompany}
                defaultValues={defaultValues}
                openerText={() => (
                  <button className="btn btn-de-primary">Approve</button>
                )}
                openerClassName="submenu_item btn p-0 m-1"
                parentId="table"
                refreshEvent="refresh_table"
                size="sm"
                onClose={hideActions}
                doNotReset={true}
                successMessage="Company Deleted !"
                submitButtonText="Delete Company"
                permissions={["super_admin", "admin"]}
              />
            ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key={1}
        submitService={createCompany}
        fields={companyMutationMeta}
        heading={"Create a New Company!"}
        openerText={() => <span>+ New Company</span>}
        openerClassName="primaryBtn_sm btn btn-primary btn-sm"
        refreshEvent="refresh_table"
        size="sm"
        successMessage="Company created Successfully!"
        doNotReset={true}
      />,
    ],
    queryService: getCompanies,
  };
};

export default getCompanyTableMeta;
