import React from "react";

function TextInput({ meta, formInstance, isInvalid }) {
  const { key, inputType, placeHolder, rules = {}, readOnly = false } = meta;
  const { register } = formInstance;

  return (
    <input
      className={`form-control`}
      readOnly={readOnly}
      type={inputType}
      placeholder={placeHolder || ""}
      disabled={meta.disabled}
      {...register(key, { ...rules })}
    />
  );
}

export default TextInput;
