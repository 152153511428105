import * as React from "react";
import { Navigate } from "react-router-dom";
import { LOGIN_PATH } from "../../constants/route";
import useAuth from "../../hooks/useAuth";
import Loader from "../Loader";
// import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, isLoading } = useAuth();
  console.log(isAuthenticated, isInitialized, isLoading);

  if (!isAuthenticated && !isLoading) {
    return <Navigate to={LOGIN_PATH} />;
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader className="d-flex justify-content-center align-items-center min-vh-100" />
      ) : (
        children
      )}
    </React.Fragment>
  );
}

export default AuthGuard;
