import inputTypes from "../../../constants/form/inputTypes";
import { getStates } from "../../../services/query/company";

const updatableCompanyMutationMeta = [
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    labelText: "Company Name",
    key: "name",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    labelText: "Company Website",
    key: "website",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.SWITCH,
    key: "is_active",
    labelText: "Active",
  },
  {
    className: "col-12 mb-3",
    inputType: inputTypes.CUSTOM,
    render: () => <h5>Company Address</h5>,
  },
  {
    className: "col-12 mb-0",
    inputType: inputTypes.TEXT,
    key: "address_line_1",
    labelText: "Address",
    placeHolder: "Line 1",
    rules: { required: true },
  },
  {
    className: "col-12 mb-3",
    inputType: inputTypes.TEXT,
    placeHolder: "Line 2",
    key: "address_line_2",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.SELECT,
    lookupQuery: getStates,
    key: "state",
    labelText: "Company State",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "city",
    labelText: "City",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "zip",
    labelText: "Zip",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "latitude",
    labelText: "Latitude",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "longitude",
    labelText: "Longitude",
    rules: { required: true },
  },
];

export default updatableCompanyMutationMeta;
