import inputTypes from "../../../constants/form/inputTypes";

export const userFilterMeta = [
  {
    inputType: inputTypes.TEXT,
    className: "col-6 mb-3",
    key: "search",
    labelText: "Search",
    placeHolder: "Search by first name, last name or id",
  },
];
