export function getFullNameOrUsername(user) {
  if (!user.first_name && !user.last_name) {
    return user.username;
  }

  const fullName = `${user.first_name.trim()} ${user.last_name.trim()}`.trim();

  if (!fullName) {
    return user.username;
  }

  return fullName;
}

export function getFullName(user) {
  const fullName = user?.first_name
    ? `${user?.first_name?.trim()} ${user?.last_name?.trim()}`?.trim()
    : "-";

  return fullName;
}
