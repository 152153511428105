export const HOME_PATH = `/`;
export const LOGIN_PATH = `/login`;
export const REGISTER_PATH = `/register`;
export const TABLES_PATH = `/tables`;
export const FORMS_PATH = `/forms`;
export const MODAL_PATH = `/modals`;
export const ORDER_MANAGEMENT = `/order-management`;
export const BID_MANAGEMENT = `/bid-management`;
export const COMPANY_MANAGEMENT = `/company-management`;
export const CATEGORY_MANAGEMENT = `/category-management`;
export const SUB_CATEGORY_MANAGEMENT = `/subcategory-management`;
export const USER_MANAGEMENT = `/user-management`;
export const NOT_FOUND_PATH = `*`;
