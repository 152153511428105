import inputTypes from "../../../constants/form/inputTypes";

const updatableUserMutationFields = [
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "first_name",
    labelText: "First Name",
    placeHolder: "Type your first name",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "last_name",
    labelText: "Last Name",
    placeHolder: "Type your last name",
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "phone",
    labelText: "Phone Number",
    rules: { required: true },
  },
  // {
  //   className: "col-6 mb-3",
  //   inputType: inputTypes.TEXT,
  //   key: "email",
  //   labelText: "Email",
  //   rules: { required: true },
  // },
];

export default updatableUserMutationFields;
