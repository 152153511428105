import { useCallback, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import NotyfContext from '../../contexts/NotyfContext';
import { eventBus } from '../../services/eventBus';
import ModalForm from '../modal/ModalForm';


function ModalFormOpener({
  submitService,
  fields,
  openerText,
  openerTextKey,
  openerIcon,
  heading = '',
  submitButtonText,
  openerClassName = 'primaryBtn',
  parentId,
  defaultValues,
  refreshEvent,
  size,
  onClose,
  doNotReset,
  successMessage,
  errorMessage,
  dataLookupQuery = null
}) {
  const notyf = useContext(NotyfContext);
  const [show, setShow] = useState(false);
  const [formLookupData, setFormLookupData] = useState(openerText)
  const mountPoint = parentId && document.getElementById(parentId);
  const onClick = () => {
    setShow(true);
  };

  const handleModalClose = useCallback(() => {
    setShow(false);
    onClose?.();
  }, [onClose]);

  const performFormDataLookup = useCallback(async () => {
    const data = await dataLookupQuery();
    setFormLookupData(data);
  }, [dataLookupQuery])

  useEffect(() => {
    if (dataLookupQuery) performFormDataLookup();
  }, [performFormDataLookup, dataLookupQuery])


  const handleSubmit = useCallback(
    async (payload) => {
      try {
        const data = await submitService(payload);
        if (!data.success) throw data;
        handleModalClose();
        eventBus.publish(refreshEvent);
        notyf.open({
          type: "success",
          message: 'Success',
          duration: 5000,
          ripple: false,
          dismissible: true,
          position: {
            x: "center",
            y: "top",
          },
        });
        return data;
      } catch (error) {
        notyf.open({
          type: "danger",
          message: 'Error',
          duration: 5000,
          ripple: false,
          dismissible: true,
          position: {
            x: "center",
            y: "top",
          },
        });
        throw error;
      }
    },
    [
      handleModalClose,
      refreshEvent,
      submitService,
      successMessage,
      errorMessage,
    ]
  );

  const modal = (
    <ModalForm
      size={size}
      show={show}
      handleModalClose={handleModalClose}
      handleSubmit={handleSubmit}
      fields={fields}
      heading={heading}
      submitButtonText={submitButtonText}
      defaultValues={defaultValues}
      doNotReset={doNotReset}
    />
  );

  return (
    <div>
      {mountPoint ? createPortal(modal, mountPoint) : modal}
      <button onClick={onClick} className={`${openerClassName}`}>
        {openerTextKey ? formLookupData?.[openerTextKey] || 'Processing...' : openerText()}
      </button>
    </div>
  );
}

export default ModalFormOpener;
