import React from "react";
import ListView from "../../components/listvVew/ListView";
import PageHeader from "../../components/pageHeader/PageHeader";
import { CATEGORY_MANAGEMENT, HOME_PATH } from "../../constants/route";
import { searchOnlyMeta } from "../../configs/meta/listFilterMeta/searchOnlyMeta";
import getCategoryTableMeta from "../../configs/meta/listMeta/categoryTableMeta";

const CategoryManagement = () => {
  return (
    <>
      <PageHeader
        title={"Category Management"}
        breadCrumbItems={[
          { title: "Dashboard", path: HOME_PATH },
          { title: "Category", path: CATEGORY_MANAGEMENT },
        ]}
      />
      <div className="row">
        {/* <TableBuilder/> */}
        <ListView
          title={"Category List"}
          filters={searchOnlyMeta}
          meta={getCategoryTableMeta()}
        />
      </div>
    </>
  );
};

export default CategoryManagement;
