import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import user_avatar from "../../../assets/images/users/user-1.jpg";
import { LOGIN_PATH } from "../../../constants/route";
import useAuth from "../../../hooks/useAuth";
import { getFullNameOrUsername } from "../../../utils/format";
// import { useOutsideClickAlerter } from '../../../hooks/useOutsideClickAlerter';

const UserProfileSettings = ({ id, isClicked, handleClick }) => {
  const { signOut, user } = useAuth();
  const navigate = useNavigate();
  const handleLogOut = () => {
    signOut();
    navigate(LOGIN_PATH);
  };
  const dropdownRef = useRef();
  // useOutsideClickAlerter(dropdownRef, () => handleClick(id), []);

  return (
    <li className="dropdown">
      <span
        className="nav-link dropdown-toggle nav-user"
        href="#"
        role="button"
        onClick={() => handleClick(id)}
      >
        <div className="d-flex align-items-center">
          <img
            src={user_avatar}
            alt="profile-user"
            className="rounded-circle me-2 thumb-sm"
          />
          <div>
            {/* <small className="d-none d-md-block font-11">Admin</small> */}
            <span className="d-none d-md-block fw-semibold font-12">
              {getFullNameOrUsername(user)}{" "}
              <i className="mdi mdi-chevron-down" />
            </span>
          </div>
        </div>
      </span>
      <div
        onClick={(e) => e.stopPropagation()}
        ref={dropdownRef}
        className={`dropdown-menu dropdown-menu-end ${
          isClicked[id] ? "show" : ""
        }`}
      >
        <a className="dropdown-item" href="#profile">
          <i className="ti ti-user font-16 me-1 align-text-bottom" /> Profile
        </a>
        <a className="dropdown-item" href="#settings">
          <i className="ti ti-settings font-16 me-1 align-text-bottom" />{" "}
          Settings
        </a>
        <div className="dropdown-divider mb-0" />
        <button
          type="button"
          onClick={handleLogOut}
          className="dropdown-item"
          href="#logout"
        >
          <i className="ti ti-power font-16 me-1 align-text-bottom" /> Logout
        </button>
      </div>
    </li>
  );
};

export default UserProfileSettings;
