import React, { useState } from "react";
import UserProfileSettings from "./dropdowns/UserProfileSettings";
import "./TopBar.css";

const initialState = {
  language: false,
  emails: false,
  notifications: false,
  userProfile: false,
};

const TopBar = ({ setEnlargeMenu }) => {
  const [isClicked, setIsClicked] = useState(initialState);

  const handleClick = (itemKey) => {
    isClicked[itemKey]
      ? setIsClicked({ ...initialState, [itemKey]: false })
      : setIsClicked({ ...initialState, [itemKey]: true });
  };

  return (
    <div className="topbar">
      {/* Navbar */}
      <nav className="navbar-custom" id="navbar-custom">
        <ul className="list-unstyled topbar-nav float-end mb-0">
          <UserProfileSettings
            id={"userProfile"}
            isClicked={isClicked}
            handleClick={handleClick}
          />
        </ul>
        {/*end topbar-nav*/}
        <ul className="list-unstyled topbar-nav mb-0">
          <li>
            <button
              onClick={() => setEnlargeMenu((prev) => !prev)}
              className="nav-link button-menu-mobile nav-icon"
              id="togglemenu"
            >
              <i className="ti ti-menu-2" />
            </button>
          </li>
          <li className="hide-phone app-search">
            <form role="search" action="#" method="get">
              <input
                type="search"
                name="search"
                className="form-control top-search mb-0"
                placeholder="Type text..."
              />
              <button type="submit">
                <i className="ti ti-search" />
              </button>
            </form>
          </li>
        </ul>
      </nav>
      {/* end navbar*/}
    </div>
  );
};

export default TopBar;
