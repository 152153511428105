import inputTypes from "../../../constants/form/inputTypes";


const loginFields = [
  {
    className: 'mb-3 col-12 ',
    inputType: inputTypes.TEXT,
    key: 'user_name',
    labelText: 'User Name',
    placeHolder: 'Enter username',
    rules: { required: true }
  },
  {
    className: 'mb-3 col-12',
    inputType: inputTypes.PASSWORD,
    key: 'user_password',
    labelText: 'Password',
    placeHolder: 'Enter password',
    rules: { required: true }
  }
];

export default loginFields;