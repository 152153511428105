import endpoints from "../../constants/endpoints";
import { adminApi } from "../client";

export const getCompanies = async (params) => {
  return await adminApi({
    endpoint: endpoints.ORGANIZATIONS,
    method: "get",
    params: { ...params },
  });
};

export const getCategories = async (params) => {
  return await adminApi({
    endpoint: endpoints.CATEGORY,
    method: "get",
    params: { ...params },
  });
};
export const getSubCategories = async (params) => {
  return await adminApi({
    endpoint: endpoints.SUB_CATEGORY,
    method: "get",
    params: { ...params },
  });
};

export const getCategoriesForDropdown = async () => {
  const cats = await adminApi({
    endpoint: endpoints.CATEGORY,
    method: "get",
    params: { page_size: 0 },
  });
  return cats.data.map((d) => ({ label: d.name, value: d.id }));
};

export const getSubCategoriesForDropdown = async () => {
  const cats = await adminApi({
    endpoint: endpoints.SUB_CATEGORY,
    method: "get",
    params: { page_size: 0 },
  });
  return cats.data.map((d) => ({ label: d.name, value: d.id }));
};

export const getStates = async (params) => {
  return [
    { name: "Alabama", id: 1 },
    { name: "Alaska", id: 2 },
    { name: "Arizona", id: 3 },
    { name: "Arkansas", id: 4 },
    { name: "California", id: 5 },
    { name: "Colorado", id: 6 },
    { name: "Connecticut", id: 7 },
    { name: "Delaware", id: 8 },
    { name: "Florida", id: 9 },
    { name: "Georgia", id: 10 },
    { name: "Hawaii", id: 11 },
    { name: "Idaho", id: 12 },
    { name: "Illinois", id: 13 },
    { name: "Indiana", id: 14 },
    { name: "Iowa", id: 15 },
    { name: "Kansas", id: 16 },
    { name: "Kentucky", id: 17 },
    { name: "Louisiana", id: 18 },
    { name: "Maine", id: 19 },
    { name: "Maryland", id: 20 },
  ].map((item) => ({ label: item.name, value: item.id }));
};

export const createCompany = async (data) => {
  const payload = {
    name: data.name,
    website: data.website,
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    username: data.username,
    password: data.password,
    phone: data.phone,
    address: {
      latitude: data.latitude,
      longitude: data.longitude,
      state: data.state,
      city: data.city,
      zip: data.zip,
      address_line_1: data.address_line_1,
      address_line_2: data.address_line_2,
    },
  };

  return await adminApi({
    endpoint: endpoints.ORGANIZATIONS,
    method: "post",
    data: payload,
  });
};

export const createCategory = async (data) => {
  const payload = {
    ...data,
  };
  return await adminApi({
    endpoint: endpoints.CATEGORY,
    method: "post",
    data: payload,
  });
};

export const createSubCategory = async (data) => {
  const payload = {
    ...data,
    image: Array.isArray(data.image) ? data.image[0] : data.image,
  };
  return await adminApi({
    endpoint: endpoints.SUB_CATEGORY,
    method: "post",
    data: payload,
  });
};

export const approveCompany = async (data) => {
  const payload = {
    is_approved: true,
    is_active: true,
  };
  return await adminApi({
    endpoint: `${endpoints.ORGANIZATIONS}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const updateCategory = async (data) => {
  const payload = {
    ...data,
  };
  return await adminApi({
    endpoint: `${endpoints.CATEGORY}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const updateSubCategory = async (data) => {
  const payload = {
    ...data,
    image: Array.isArray(data.image) ? data.image[0] : data.image,
  };
  return await adminApi({
    endpoint: `${endpoints.SUB_CATEGORY}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const updateCompany = async (data) => {
  const payload = {
    name: data.name,
    website: data.website,
    is_active: data.is_active,
    address: {
      latitude: data.latitude,
      longitude: data.longitude,
      state: data.state,
      city: data.city,
      zip: data.zip,
      address_line_1: data.address_line_1,
      address_line_2: data.address_line_2,
    },
  };
  return await adminApi({
    endpoint: `${endpoints.ORGANIZATIONS}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const getCompanyMembers = async (id) => {
  return await adminApi({
    endpoint: `${endpoints.COMPANY_MEMBERS}${id}/`,
    method: "get",
    params: { page_size: 0 },
  });
};

export const getServiceLocations = async (id) => {
  return await adminApi({
    endpoint: `${endpoints.COMPANY_SERVICE_LOCATIONS}${id}/`,
    method: "get",
    params: { page_size: 0 },
  });
};

export const resetPassword = async (data) => {
  // return await adminApi({
  //   endpoint: endpoints.RESET_PASSWORD,
  //   method: "post",
  //   data: data,
  // });
};

export const verifyResetPassword = async (data) => {
  // return await adminApi({
  //   endpoint: endpoints.RESET_PASSWORD_VERIFY,
  //   method: "post",
  //   data: data,
  // });
};

export const changePassword = async (data) => {
  // return await adminApi({
  //   endpoint: endpoints.CHANGE_PASSWORD,
  //   method: "post",
  //   data: data,
  // });
};
