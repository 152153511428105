import React, { useCallback, useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import NotyfContext from "../../contexts/NotyfContext";
import { eventBus } from "../../services/eventBus";
import ModalForm from "../modal/ModalForm";
import Modal from "../modal/Modal";

function ConfirmationModal({
  submitService, // Add submitService prop
  openerText,
  defaultValues,
  openerTextKey,
  openerClassName = "primaryBtn",
  parentId,
  refreshEvent,
  onClose,
  dataLookupQuery = null,
}) {
  const notyf = useContext(NotyfContext);
  const [formLookupData, setFormLookupData] = useState(openerText);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false); // State for delete confirmation


  const performFormDataLookup = useCallback(async () => {
    const data = await dataLookupQuery();
    setFormLookupData(data);
  }, [dataLookupQuery]);

  useEffect(() => {
    if (dataLookupQuery) performFormDataLookup();
  }, [performFormDataLookup, dataLookupQuery]);

  const handleDeleteConfirmation = () => {
    setDeleteConfirmation(true);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmation(false);
  };

  const handleDelete = async () => {
    try {
      await submitService(defaultValues); // Call the submitService function passed as a prop
      setDeleteConfirmation(false);
      eventBus.publish(refreshEvent);
      notyf.open({
        type: "success",
        message: "Deleted successfully",
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
    } catch (error) {
      notyf.open({
        type: "danger",
        message: "Error deleting",
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
    }
  };

  return (
    <div>
      {deleteConfirmation ? (
        <Modal handleModalClose={handleDeleteCancel}>
          <div className="new_agent_modal_header">
            <h2>Are you sure?</h2>
          </div>
          <div className="new_agent_form_container mb-2">
            <button
              className="btn btn-de-primary btn-md"
              onClick={handleDelete}
            >
              Yes
            </button>
            <button
              className="btn btn-de-danger ms-2 btn-md"
              onClick={handleDeleteCancel}
            >
              Cancel
            </button>
          </div>
        </Modal>
      ) : (
        <button
          onClick={handleDeleteConfirmation}
          className={`${openerClassName}`}
        >
          {openerTextKey
            ? formLookupData?.[openerTextKey] || "Processing..."
            : openerText()}
        </button>
      )}
      {/* {!deleteConfirmation && (
        <button
          onClick={handleDeleteConfirmation}
          className={`${openerClassName}`}
        >
          {openerText()}
        </button>
      )} */}
    </div>
  );
}

export default ConfirmationModal;
