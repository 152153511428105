import React, { useState, useEffect, useContext, useCallback } from "react";
import NotyfContext from "../../../contexts/NotyfContext";
import { uploadFile } from "../../../services/query/upload";
import FilePreview from "./FilePreview";

const FileUpload = ({ meta, formInstance, defaultValue = [], setLoading }) => {
  const { key, inputType, placeholder, rules = {}, multiple } = meta;
  const { setValue, watch, register } = formInstance;
  const [files, setFiles] = useState(
    Array.isArray(defaultValue) ? defaultValue : [defaultValue]
  );

  const notyf = useContext(NotyfContext);

  const [watchValue] = watch([key]);

  const uploadFiles = useCallback(
    async (e) => {
      const submittedFiles = Array.from(e.currentTarget.files);
      try {
        setLoading(true)
        const res = await uploadFile(submittedFiles, "course_image");
        if (!res.success) throw res;
        const newFiles = [...files, res?.data.url];
        setValue(key, newFiles);
      } catch (error) {
        const message = error.error || "Something went wrong";
        notyf.open({
          type: "danger",
          message: message,
          duration: 5000,
          ripple: false,
          dismissible: true,
          position: {
            x: "center",
            y: "top",
          },
        });
      } finally {
        setLoading(false)
      }
    },
    [files, key, notyf, setLoading, setValue]
  );

  useEffect(() => {
    if (watchValue)
      setFiles(Array.isArray(watchValue) ? watchValue : [watchValue]);
  }, [watchValue]);

  const deleteFile = useCallback(
    (file) => {
      const newFiles = files.filter((f) => f !== file);
      setValue(key, newFiles);
    },
    [files, key, setValue]
  );

  return (
    <>
      {!files.length &&<div className="card-body">
        <input {...register(key, { ...rules })} className="d-none" />
          <input
            type={"file"}
            accept=".jpg, .jpeg, .png, image/jpg, image/jpeg, image/png"
            placeholder={placeholder || " "}
            alt="image"
            className="form-control"
            onChange={(data) => {
              uploadFiles(data);
            }}
            multiple={multiple}
          />
      </div>}
      {!!files.length && (
        <div
          id="gallery"
          className="form__input__file form__input__file--preview"
        >
          {files?.map((file) => (
            <FilePreview key={file} file={file} deleteFile={deleteFile} />
          ))}
        </div>
      )}
    </>
  );
};

export default FileUpload;
