import React from 'react';
import FormBuilder from '../form/formBuilder/FormBuilder';
import Modal from './Modal';

const ModalForm = ({
  show,
  handleModalClose,
  handleSubmit,
  defaultValues,
  size,
  fields,
  heading,
  submitButtonText = 'Submit',
  doNotReset,
}) => {
  if (show) {
    return (
      <Modal handleModalClose={handleModalClose} size={size}>
        <div className="new_agent_modal_header">
          <h3 className="table_title card-title">{heading}</h3>
        </div>
        <hr />
        <div className="new_agent_form_container mb-2">
          <FormBuilder
            onSubmit={(data) => {
              handleSubmit(data);
            }}
            fields={fields}
            submitButton={{
              className: 'primaryBtn btn btn-primary',
              text: submitButtonText,
            }}
            defaultValues={defaultValues}
            doNotReset={doNotReset}
            actionContainerClassName={'form-actions col-12'}
          />
        </div>
      </Modal>
    );
  }
};

export default ModalForm;
