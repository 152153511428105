import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createCategory,
  getCategories,
  updateCategory,
} from "../../../services/query/company";
import categoryMutationFields from "../mutationMeta/categoryMutationFields";

const getCategoryTableMeta = () => {
  return {
    columns: [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues, hideActions) => (
            <ModalFormOpener
              key="edit"
              submitService={updateCategory}
              defaultValues={defaultValues}
              fields={categoryMutationFields}
              heading={"Let’s Edit a Category!"}
              openerText={() => (
                <span>
                  <i className="mdi mdi-square-edit-outline text-secondary font-16"></i>
                </span>
              )}
              openerClassName="submenu_item btn p-0 m-1"
              parentId="table"
              refreshEvent="refresh_table"
              size="sm"
              onClose={hideActions}
              doNotReset={true}
              successMessage="Category updated Successfully!"
              permissions={["super_admin", "admin"]}
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key={1}
        submitService={createCategory}
        fields={categoryMutationFields}
        heading={"Create a New Category!"}
        openerText={() => <span>+ New Category</span>}
        openerClassName="primaryBtn_sm btn btn-primary btn-sm"
        refreshEvent="refresh_table"
        size="sm"
        successMessage="Category created Successfully!"
        doNotReset={true}
      />,
    ],
    queryService: getCategories,
  };
};

export default getCategoryTableMeta;
