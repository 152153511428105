import React from 'react'

const SwitchInput = ({ meta, formInstance, isInvalid }) => {
  const { key, rules = {}, className, labelText } = meta;
  const { register } = formInstance;
  return (
    <>
      <div className="form-check form-switch ">
        <input
          id={key}
          isInvalid={isInvalid}
          className="form-check-input"
          type="checkbox"
          {...register(key, { ...rules })}
        />
        <label
          className="form-check-label"
          htmlFor={key}
        >
          {labelText}
        </label>
      </div>
    </>
  )
}

export default SwitchInput
