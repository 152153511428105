import React, { useEffect } from "react";
import inputTypes from "../../../constants/form/inputTypes";
import CheckboxInput from "../inputs/CheckboxInput";
import CreatableSelectInput from "../inputs/CreatableSelectInput";
import DateInput from "../inputs/DateInput";
import DateRangeInput from "../inputs/DateRangeInput";
import DateTimeInput from "../inputs/DateTimeInput";
import FileUpload from "../inputs/FileUpload";
import InputError from "../inputs/InputError";
import ListInput from "../inputs/ListInput";
import RadioInput from "../inputs/RadioInput";
import RichTextInput from "../inputs/RichTextInput";
import SelectInput from "../inputs/SelectInput";
import SwitchInput from "../inputs/SwitchInput";
import TextAreaInput from "../inputs/TextAreaInput";
import TextInput from "../inputs/TextInput";
import InputLabel from "../label/inputLabel";

const InputBuilder = ({
  meta,
  defaultValue: defaultValueProp,
  formInstance,
  isWatchSubscribed,
  dependencyValues,
  setLoading,
  renderLabel = true,
}) => {
  const { className = "", key, inputType, prependLabel, append } = meta;
  const defaultValue =
    defaultValueProp !== undefined ? defaultValueProp : meta.defaultValue;
  const {
    formState: { errors },
    setValue,
  } = formInstance;

  useEffect(() => {
    if (defaultValue !== undefined) setValue(key, defaultValue);
  }, [key, defaultValue, setValue]);

  return (
    <div className={className}>
      {!!prependLabel && prependLabel()}

      {inputType !== inputTypes.CHECKBOX && renderLabel && (
        <InputLabel meta={meta} />
      )}

      {/* TYPE :: Text || Pass */}
      {(inputType === inputTypes.TEXT ||
        inputType === inputTypes.PASSWORD ||
        inputType === inputTypes.NUMBER) && (
          <TextInput
            meta={meta}
            formInstance={formInstance}
            isInvalid={errors?.[key] ? true : false}
          />
        )}

      {inputType === inputTypes.SELECT && (
        <SelectInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
          dependencyValues={dependencyValues}
          defaultValue={defaultValue}
        />
      )}
      {inputType === inputTypes.CREATABLE_SELECT && (
        <CreatableSelectInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
          dependencyValues={dependencyValues}
          defaultValue={defaultValue}
        />
      )}

      {inputType === inputTypes.TEXTAREA && (
        <TextAreaInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
        />
      )}
      {inputType === inputTypes.DATE && (
        <DateInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
        />
      )}
      {inputType === inputTypes.DATE_RANGE && (
        <DateRangeInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
        />
      )}
      {inputType === inputTypes.RADIO && (
        <RadioInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
        />
      )}
      {inputType === inputTypes.CHECKBOX && (
        <CheckboxInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
        />
      )}
      {inputType === inputTypes.SWITCH && (
        <SwitchInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
        />
      )}


      {inputType === inputTypes.LIST_INPUT && (
        <ListInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
        />
      )}

      {/* {inputType === inputTypes.QUESTION_INPUT && (
        <QuestionInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
          defaultValue={defaultValue}
        />
      )} */}

      {inputType === inputTypes.DATE_TIME && (
        <DateTimeInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
          defaultValue={defaultValue}
        />
      )}

      {inputType === inputTypes.RICH_TEXT && (
        <RichTextInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
          defaultValue={defaultValue}
        />
      )}

      {(inputType === inputTypes.FILE || inputType === inputTypes.IMAGE) && (
        <FileUpload
          meta={meta}
          formInstance={formInstance}
          defaultValue={defaultValueProp}
          setLoading={setLoading}
        />
      )}


      <InputError error={errors?.[key]} />
      {inputType !== inputTypes.SELECT && (
        <span className="form__append">{append?.()}</span>
      )}
    </div>
  );
};

export default InputBuilder;
