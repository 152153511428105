import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import FormBuilder from '../../components/form/formBuilder/FormBuilder'
import Modal from '../../components/modal/Modal'
import PageHeader from '../../components/pageHeader/PageHeader'
import fields from '../../configs/meta/formFilelds/fields'
import { HOME_PATH, MODAL_PATH } from '../../constants/route'

const Modals = () => {
  const [show, setShow] = useState(false);

  const handleSubmit = (data) => {
    console.log(data)
  }

  return (
    <>
      <PageHeader title={'Modals'} breadCrumbItems={[{ title: 'Dashboard', path: HOME_PATH }, { title: 'Modals', path: MODAL_PATH }]} />
      <div className='row'>
        <div className="col-12">
          <div className="border-0 d-flex justify-content-between" >
            <div className="border-0">This is a custom made modal</div>
            <div className="border-0"><button type="button" className="btn btn-de-primary btn-sm" onClick={() => setShow(true)}>Click me</button></div>
          </div>
        </div>
      </div>

      {show && <Modal handleModalClose={() => setShow(false)}>
        <div className='mt-5'>
          <FormBuilder
            onSubmit={handleSubmit}
            fields={fields}
            submitButton={{
              className: "btn btn-primary btn-lg rounded-0",
              label: "Login",
              isDisabled: false,
            }}
            className=""
            doNotReset={true}
            actionsPrev={[
              <div>
                <Link to={'#'} className="display-block">
                  Forgot Password
                </Link>
                <br />
              </div>,
            ]}

          />
        </div>
      </Modal>}
    </>
  )
}

export default Modals
