import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import inputTypes from "../../../constants/form/inputTypes";
import { eventBus } from "../../../services/eventBus";
import InputBuilder from "../inputBuilder/inputBuilder";
import InputLabel from "../label/inputLabel";


const FormBuilder = ({
  fields: fieldsProp = [],
  onSubmit,
  submitButton,
  className,
  doNotReset,
  defaultValues,
  actions,
  inputsContainerClassName,
  actionsContainerClassName,
  actionsPrev,
  resetEvent = "reset_form",
}) => {
  const formInstance = useForm({ defaultValues: defaultValues || {} });
  const { watch, setValue, unregister, getValues } = formInstance;
  const [fields, setFields] = useState(fieldsProp);
  const [isWatchSubscribed, setIsWatchSubscribed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dependencyValues, setDependencyValues] = useState({});

  /* Reset On Submit Success */
  const isSubmitSuccessful = formInstance?.formState?.isSubmitSuccessful;
  const isSubmitting = formInstance?.formState?.isSubmitting;

  const toggleVisibility = useCallback(
    (key, status) => {
      setFields((fields) => {
        unregister(key);
        return fields.reduce((a, c) => {
          if (c.key === key) c.hide = status;
          a.push(c);
          return a;
        }, []);
      });
    },
    [setFields, unregister]
  );

  useEffect(() => {
    setFields(fieldsProp);
  }, [fieldsProp]);

  useEffect(() => {
    eventBus.subscribe(resetEvent, () => formInstance?.reset());

    return () => {
      eventBus.unsubscribe(resetEvent);
    };
  }, [formInstance, resetEvent]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      for (const field of fields) {
        if (!field.dependencies?.includes(name)) continue;
        setDependencyValues((prevVal) => ({
          ...prevVal,
          [name]: value[name],
        }));
        field.onDependencyValueChange?.(value, name, {
          setValue,
          toggleVisibility,
        });
      }
    });

    if (subscription) setIsWatchSubscribed(true);
    return () => subscription.unsubscribe();
  }, [watch, fields, setValue, toggleVisibility]);

  useEffect(() => {
    isSubmitSuccessful && !doNotReset && formInstance?.reset();
  }, [isSubmitSuccessful, formInstance, doNotReset, isSubmitting]);

  const handleSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <div>
      <form
        onSubmit={formInstance.handleSubmit(handleSubmit)}
        className={className}
      >
        <div className={`${inputsContainerClassName || 'row'} `}>
          {fields
            .filter((field) => !field.hide)
            .map((field, idx) =>
              !field.inputType && getValues(field.key) !== undefined ? (
                <div key={idx} className={field.className || ""}>
                  <InputLabel meta={field} className={"description__label"} />
                  <p className="ps-3">{getValues(field.key)}</p>
                </div>
              ) : field.inputType === inputTypes.CUSTOM ? (
                <div key={idx} className={field.className || ""}>
                  {field?.render()}
                </div>
              ) : (
                <InputBuilder
                  key={idx}
                  meta={field}
                  defaultValue={defaultValues?.[field.key]}
                  formInstance={formInstance}
                  isWatchSubscribed={isWatchSubscribed}
                  setLoading={setLoading}
                  dependencyValues={Object.keys(dependencyValues).reduce(
                    (a, c) => {
                      if (
                        field.dependencies?.includes(c) &&
                        dependencyValues[c] !== undefined
                      )
                        a[c] = dependencyValues[c];
                      return a;
                    },
                    {}
                  )}
                />
              )
            )}
        </div>

        {actionsPrev && actionsPrev.map((action, index) => <Fragment key={`action-${index}`}>{action}</Fragment>)}
        <div className={` ${actionsContainerClassName || 'd-flex align-items-end'}`}>
          <button
            className={`
             ${submitButton?.className} ${!!isSubmitting && "disableBtn"
              }`}
            type="submit"
            disabled={isSubmitting || loading}
          >
            {submitButton?.label ? submitButton.label : "Submit"}
          </button>

          {actions && actions.map((btn) => btn)}
        </div>
      </form>
    </div>
  );
};

export default FormBuilder;
