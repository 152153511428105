import React from "react";
import ListView from "../../components/listvVew/ListView";
import PageHeader from "../../components/pageHeader/PageHeader";
import { HOME_PATH, SUB_CATEGORY_MANAGEMENT } from "../../constants/route";
import { searchOnlyMeta } from "../../configs/meta/listFilterMeta/searchOnlyMeta";
import getSubcategoryTableMeta from "../../configs/meta/listMeta/subcategoryTableMeta";

const SubCategoryManagement = () => {
  return (
    <>
      <PageHeader
        title={"Sub-category Management"}
        breadCrumbItems={[
          { title: "Dashboard", path: HOME_PATH },
          { title: "Sub Category", path: SUB_CATEGORY_MANAGEMENT },
        ]}
      />
      <div className="row">
        {/* <TableBuilder/> */}
        <ListView
          title={"Sub-category List"}
          filters={searchOnlyMeta}
          meta={getSubcategoryTableMeta()}
        />
      </div>
    </>
  );
};

export default SubCategoryManagement;
