import inputTypes from '../../../constants/form/inputTypes';
import inputVariants from '../../../constants/form/inputVariants';


const fields = [
  {
    className: 'mb-3 col-6',
    inputType: inputTypes.TEXT,
    variant: inputVariants.STANDARD,
    key: 'user_name',
    labelText: 'User Name',
    placeHolder: 'Type text here',
    rules: { required: true }
  },
  {
    className: 'mb-3 col-6',
    inputType: inputTypes.PASSWORD,
    key: 'user_password',
    labelText: 'User Password',
    placeHolder: 'Type your password here',
    rules: { required: true }
  },
  {
    className: 'mb-3',
    inputType: inputTypes.TEXTAREA,
    key: 'user_address',
    labelText: 'User Address',
    placeHolder: 'Type your address here',
    rules: { required: true }
  },
  {
    className: 'mb-3',
    inputType: inputTypes.DATE,
    key: 'arrival_date',
    labelText: 'Arrival Date',
    placeHolder: 'Type your address here',
    rules: { required: true }
  },
  {
    className: 'mb-3',
    inputType: inputTypes.DATE_RANGE,
    key: 'date_range',
    labelText: 'Arrival Date',
    placeHolder: 'Type your address here',
    rules: { required: true }
  },
  {
    className: 'mb-3',
    inputType: inputTypes.SELECT,
    key: 'coutry',
    labelText: 'Country',
    isMulti: true,
    placeHolder: 'Type your address here',
    rules: { required: true },
    // lookupQuery: getInstructorForDropdown, 
    options: [
      {
        label: "Bangladesh",
        value: "bd",
      },
      {
        label: "India",
        value: "india",
      },
      {
        label: "United Kindom",
        value: "uk",
      },
    ],
  },
  {
    className: 'mb-3',
    inputType: inputTypes.CHECKBOX,
    key: 'agree',
    labelText: 'I agree',
    placeHolder: 'I agree',
    rules: { required: true }
  },
  {
    className: 'mb-3',
    inputType: inputTypes.SWITCH,
    key: 'toggle',
    labelText: 'Toggle',
    placeHolder: 'Toggle',
    rules: { required: true }
  },
  {
    className: "mb-3",
    childrenClassName: "d-flex",
    inputType: inputTypes.RADIO,
    key: "duration_select",
    labelText: "Quiz Duration",
    // rules: { required: true },
    options: [
      {
        label: "10 Hours",
        value: "10-hours",
      },
      {
        label: "No Limit",
        value: "no_limit",
      },
    ],
  },
  {
    className: 'mb-3',
    inputType: inputTypes.FILE,
    key: 'file',
    labelText: 'Upload Your file',
    placeHolder: 'File',
    // isMulti: true,
    // rules: { required: true }
  },
  {
    className: 'mb-3',
    inputType: inputTypes.DATE_TIME,
    key: 'date-time',
    labelText: 'Pick Date Time',
    placeHolder: 'Date time',
  },
  {
    className: 'mb-3',
    inputType: inputTypes.RICH_TEXT,
    key: 'rich-text',
    labelText: 'Rich Text',
    placeHolder: 'Write something',
  },

  {
    inputType: inputTypes.LIST_INPUT,
    key: "amenities_bn",
    labelText: "List Input",
    className: "mb-1 row",
    fields: [
      {
        inputType: inputTypes.TEXT,
        className: "col-6 mb-2 px-2 d-inline-block",
        labelText: "Name",
        key: "name",
        placeHolder: "Name",
      },
      {
        inputType: inputTypes.PASSWORD,
        className: "col-6 mb-2 px-2 d-inline-block",
        labelText: "pass",
        key: "pass",
        placeHolder: "pass",
      },
    ],
  },
];
export default fields