import endpoints from "../../constants/endpoints";
import { adminApi } from "../client";

export const getOrders = async (params) => {
  return await adminApi({
    endpoint: endpoints.ORDERS,
    method: "get",
    params: { ...params },
  });
};
