import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createSubCategory,
  getSubCategories,
  updateSubCategory,
} from "../../../services/query/company";
import subcategoryMutationFields from "../mutationMeta/subcategoryMutationFields";

const getSubcategoryTableMeta = () => {
  return {
    columns: [
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ value }) => value.name,
      },
      {
        Header: "Sub Category",
        accessor: "name",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues, hideActions) => (
            <ModalFormOpener
              key="edit"
              submitService={updateSubCategory}
              defaultValues={{
                ...defaultValues,
                category: defaultValues.category.id,
              }}
              fields={subcategoryMutationFields}
              heading={"Let’s Edit a Sub Category!"}
              openerText={() => (
                <span>
                  <i className="mdi mdi-square-edit-outline text-secondary font-16"></i>
                </span>
              )}
              openerClassName="submenu_item btn p-0 m-1"
              parentId="table"
              refreshEvent="refresh_table"
              size="sm"
              onClose={hideActions}
              doNotReset={true}
              successMessage="Sub Category updated Successfully!"
              permissions={["super_admin", "admin"]}
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key={1}
        submitService={createSubCategory}
        fields={subcategoryMutationFields}
        heading={"Create a New Sub Category!"}
        openerText={() => <span>+ New Sub Category</span>}
        openerClassName="primaryBtn_sm btn btn-primary btn-sm"
        refreshEvent="refresh_table"
        size="sm"
        successMessage="Sub Category created Successfully!"
        doNotReset={true}
      />,
    ],
    queryService: getSubCategories,
  };
};

export default getSubcategoryTableMeta;
