import inputTypes from "../../../constants/form/inputTypes";
import { getCategoriesForDropdown } from "../../../services/query/company";

const subcategoryMutationFields = [
  {
    className: "col-6 mb-3",
    inputType: inputTypes.SELECT,
    key: "category",
    labelText: "Category",
    lookupQuery: getCategoriesForDropdown,
    rules: { required: true },
  },
  {
    className: "col-6 mb-3",
    inputType: inputTypes.TEXT,
    key: "name",
    labelText: "Sub Category",
    rules: { required: true },
  },
  {
    className: "col-12 mb-3",
    inputType: inputTypes.IMAGE,
    key: "image",
    labelText: "Image",
    placeHolder: "Image",
  },
];

export default subcategoryMutationFields;
